<template>
  <AccountUserBind :id="id" :page="page" :filter="filter" :size="size"/>
</template>
<script>

import AccountUserBind from '@/components/account/Account/components/AccountUserBind.vue';

export default {
  name: 'AccountUserBindView',
  components: {
    AccountUserBind
  },
  props: {
    id: { type: String, required: true },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
}
</script>
